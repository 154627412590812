
const FallbackComponent = ({ image = '', desc = '', title, handler = null, buttonText = '', details }: { image?: string, desc?: string, title: string, handler?: () => void, buttonText?: string, details?: React.ReactNode }) => {
    return (
        <div className="fallback__page">
            <div className="fallback__page__inner">
                <div className="fallback__page__content">
                    {image && <img src={image} alt="empty" className="fallback__page__img" />}
                    <p className="fallback__page__headline">
                        {title || ''}
                    </p>
                    {desc && <p className="fallback__page__description">
                        {desc}
                    </p>}
                    {!!handler && buttonText ?
                        <button className='button--filled table-empty__button' onClick={() => handler()}>
                            {buttonText}
                        </button> : ''}
                </div>
            </div>
        </div>
    )
}

export default FallbackComponent