import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import { AuthProvider } from "@ucrecruits/globalstyle/components/AuthProvider/AuthProvider";
import { SubscriptionProvider } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: (props: any) => {
    return (
      <AuthProvider>
        <SubscriptionProvider>
        <Root {...props} />
        </SubscriptionProvider>
      </AuthProvider>
    );
  },
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
