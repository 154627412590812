import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useHasPermission } from '../components/AuthProvider';
import NoActiveSubscriptionPage from '../components/FallbackPages/NoActiveSubscriptionPage';
import SmallLoader from '../components/Table/SmallLoader';
interface SubscriptionContextType {
    subscriptionData: any;
    isLoadingSubscription: boolean;
    packageType: string | null;
    checkCompanySubscription: () => boolean;
    checkCurrentPackageType: (pacType: string | string[]) => boolean;
    hasCompanySubscriptionElement: (element: any) => JSX.Element;
    setIsLoadingSubscription: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscription = () => {
    const context = React.useContext(SubscriptionContext);
    if (!context) {
        throw new Error('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
};

interface SubscriptionProviderProps {
    children: ReactNode;
}

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
    const token: string | null = localStorage.getItem("token");
    const config = token ? {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    } : {};

    const [isLoadingSubscription, setIsLoadingSubscription] = useState<boolean>(false);
    const [subscriptionData, setSubscriptionData] = useState<any>(null);
    const [packageType, setPackageType] = useState<string | null>(null);
    const { checkUserPermission, companyId } = useHasPermission()

    useEffect(() => {
        setIsLoadingSubscription(true);
        const getData = async () => {
            try {
                const res = await axios(`https://recruitment-micro.urecruits.com/api/subscription/get-company-main-subscription`, config);
                // const res = await axios(`http://localhost:5478/api/subscription/get-company-main-subscription`, config);
                if (res.data && Object.keys(res.data).length > 0) {
                    setSubscriptionData(res.data);
                    setPackageType(res.data.packageType ?? null);
                }
                setIsLoadingSubscription(false);
            } catch (err) {
                setIsLoadingSubscription(false);
            }
        };
        !subscriptionData && !!companyId && getData();
    }, [companyId]);

    const checkCompanySubscription = () => {
        return !isLoadingSubscription && subscriptionData && subscriptionData.subId !== "noSubscription";
    };

    const checkCurrentPackageType = (pacType: string | string[]) => {
        if (!isLoadingSubscription && packageType && pacType) {
            if (typeof pacType === 'string' && packageType === pacType) {
                return true;
            }
            if (Array.isArray(pacType) && pacType.includes(packageType)) {
                return true;
            }
        }
        return false;
    };

    const hasCompanySubscriptionElement = (element: any) => {
        if (checkCompanySubscription()) {
            return element;
        }
        if (isLoadingSubscription || localStorage.getItem('firstLogin') == 'true') {
            return <SmallLoader />
        }
        return <NoActiveSubscriptionPage />;
    };

    return (
        <SubscriptionContext.Provider
            value={{
                subscriptionData,
                isLoadingSubscription,
                packageType,
                checkCompanySubscription,
                checkCurrentPackageType,
                hasCompanySubscriptionElement,
                setIsLoadingSubscription,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};
