import FallbackComponent from "./FallbackComponent"
import UnauthorizeImg from '../../images/icons/fallback/unauthorized.svg'

const UnauthorizePage = () => {
  return (
    <FallbackComponent
      image={UnauthorizeImg}
      title="Access Denied"
      desc="You don’t have the necessary permissions to access this page or feature."
      details={
        <>
          <h4>What you can do:</h4>
          <ul>
            <li>Ensure you’re logged into the correct account.</li>
            <li>Contact your administrator to access this page.</li>
            <li>Return to <a href={window.location.origin}>Dashboard</a> to explore other available options.</li>
          </ul>
        </>
      }
    />
  )
}

export default UnauthorizePage
