import FallbackComponent from "./FallbackComponent"
import upgradeImg from '../../images/icons/fallback/upgrade-subscription.svg'
const UpgradeSubscription = () => {
    return (
        <FallbackComponent
            image={upgradeImg}
            title="New Feature Discovered!"
            buttonText="Upgrade Now"
            desc="Upgrade to the Full Cycle Package to unlock access to this feature and other exclusive features."
            handler={() => window.location.href = `${window.location.origin}/profile-setting?active=subscriptions`}
        />
    )
}

export default UpgradeSubscription
