import FallbackComponent from "./FallbackComponent"

const NotFoundPage = () => {
  return (
    <FallbackComponent
      title="Oops! Page Not Found"
      desc="It seems the page you’re looking for doesn’t exist."
      buttonText="Go back to Homepage"
      handler={() => window.location.href = window.location.origin}
    />
  )
}

export default NotFoundPage
