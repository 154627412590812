// import { CodingAssessments } from "../components/CodingAssessments";
import {
  RouteComponentProps,
  LocationProvider,
  createHistory,
} from "@reach/router";

import "../styles/main.scss";
// import LiveTask from "../components/tasks/LiveTask";
import Home from "../screen/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import LiveTaskNextStep from "../components/tasks/LiveTaskNextStep";
// import NavigateToSteps from "../components/tasks/NavigateToSteps";
import { Provider } from "react-redux";
import store from "../config/store";
import { useHasPermission, UnAuthorizePageComponent, NotFoundPageComponent, permissionRoutesType, useSubscription, UpgradeSubscriptionComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
// import HomeTaskStepGeneral from "../components/tasks/HomeTaskStepGeneral";
// import NavigateToLiveTask from "../components/tasks/NavigateToLiveTask";

export default function Root(props) {
  const history = createHistory(window);
  const { onAuthorizedRoutes, companyId } = useHasPermission()
  const { isLoadingSubscription, hasCompanySubscriptionElement, checkCurrentPackageType } = companyId && useSubscription();

  const routes: Array<permissionRoutesType>=[
{
      path:'/manage-assignment',
      element: <Home />,
      module:'assessment',
      permissions: ['add', 'edit', 'view'],
      planPackages:['assessment','fullcycle']
}
  ]
  return (
    <>
      <LocationProvider history={history}>
        <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {companyId && 
                routes.map(route => {
                  const isAuthorized = route.planPackages
                    ? checkCurrentPackageType(route.planPackages) &&
                    onAuthorizedRoutes(route.module, route.permissions, route?.option || "")
                    : onAuthorizedRoutes(route.module, route.permissions, route?.option || "");

                  const routeElement = isAuthorized ? route.element : route.planPackages ? <UpgradeSubscriptionComponent/> : <UnAuthorizePageComponent />;

                  return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(routeElement)} />;
                })
            }
            <Route path="/manage-assignment/*" element={companyId && isLoadingSubscription ? <h1>Loading...</h1> :<NotFoundPageComponent/>}/>
          </Routes>
          </BrowserRouter>
            {/* <LiveTask path="/coding-assessments/live-task" />
            <NavigateToLiveTask default>
              <LiveTaskNextStep default />
            </NavigateToLiveTask> */}
            {/* <HomeTask path="/coding-assessments/take-home-task"> */}
            {/* <NavigateToSteps default> */}
            {/* </NavigateToSteps> */}
            {/* </HomeTask> */}
            {/* <CodingAssessments path="/coding-assessments" /> */}
        </Provider>
      </LocationProvider>
    </>
  );
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
