import FallbackComponent from './FallbackComponent'
import NoSubscriptionImg from '../../images/icons/table/empty-table.svg'

const NoActiveSubscriptionPage = () => {
    return (
        <FallbackComponent
            image={NoSubscriptionImg}
            title="No Active Subscription"
            desc="It looks like you don’t have an active subscription at the moment."
            buttonText="Activate"
            handler={() => window.location.href = window.location.origin + '/profile-setting?active=subscriptions'}
            details={
                <div className="fallback__page__details">
                    <h4>To continue managing job postings, assessments, and candidates:</h4>
                    <ul>
                        <li>Upgrade Your Plan to unlock access to our ATS features according to the packages.</li>
                        <li>Activate your subscription to streamline your hiring process and improve recruitment efficiency.</li>
                    </ul>
                    <p>Need help choosing a plan? <a href="https://support.urecruits.com/support/home" target="_blank">Contact Support</a> — we’re here to assist you!</p>
                </div>
            }
        />
    )
}

export default NoActiveSubscriptionPage